//TODO: Create React Component for Navbar Product
import React from "react";
import { Link } from "react-router-dom";
import FeatureIcon from "./FeatureIcon";
import Icon from "./Icon";

const NavBarProduct = ({ products, setMobileNavOpen, classes }) => {
  return (
    <div className="flex flex-col gap-2 px-3">
      {products.map((product) => (
        <Link
          key={product.url}
          to={product.url}
          onClick={() => setMobileNavOpen(false)}
          className={`group hover:bg-slate-300/[.12] transition ease-out flex gap-2.5 items-center p-2 rounded-lg`}
        >
          <div className="flex items-center">
            <FeatureIcon color={product.iconBg}>
              <div className="overflow-hidden rounded">
                <Icon
                  src={product.image}
                  alt=""
                  width={500}
                  height={100}
                  className="app-icon"
                />
              </div>
              {/* <CodeBracketIcon /> */}
            </FeatureIcon>
          </div>
          <div className="flex flex-col gap-1">
            <div class="flex items-center gap-2">
              <h5 className="text-xl font-semibold text-gray-200 md:text-md lg:text-sm">
                {product.title}
              </h5>
              {product.tag && <Tag>{product.tag}</Tag>}
            </div>
            <p className="text-[15px] lg:text-[13px] md:text-[14px]  leading-4 text-gray-400 line-clamp-2">
              {product.description}
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
};

function Tag({ children }) {
  return (
    <div
      className="px-2 py-0.5 text-xs text-blue-400 border-2 border-blue-400/60 capitalize rounded-full
     w-fit bg-blue-400/[.15] shadow-[0_0_5px_0] shadow-blue-400/50"
    >
      <p className="font-bold truncate">{children}</p>
    </div>
  );
}

export default NavBarProduct;

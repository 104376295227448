import React from "react";
import Meta from "./../components/Meta";
import NewsletterSection from "./../components/NewsletterSection";

function ProductPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <NewsletterSection
        bgColor="bg-primary"
        textColor="text-gray-100"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="WhisperScript v2 Coming Soon!"
        subtitle="Sign up to our newsletter or follow us on social media to stay updated on our progress."
        strapline="Stay in the Loop"
      />
    </>
  );
}

export default ProductPage;

import { useEffect } from "react";
import { useRouter } from "./router";
import analyticsInit from "analytics";
//import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";
// import { history } from "./router.js";

// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analytics = analyticsInit({
  debug: process.env.NODE_ENV !== "production",
  plugins: [
    // Instructions: https://divjoy.com/docs/segment
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    }),
  ],
});

// Track pageview on router change
export function Analytics() {
  const router = useRouter();
  useEffect(() => {
    analytics.page();
  }, [router.location]);
  return null;
}

export default analytics;

import React from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "../util/auth";
import OrderConfirmationSection from "../components/OrderConfirmation";

function OrderConfirmationPage(props) {
  return (
    <>
      <Meta title="Wavery - OrderConfirmed" />
      <OrderConfirmationSection
        title="Your Order Has Been Confirmed"
        subtitle="Get started right away with your new transcription toolbox"
        strapline="THANK YOU!!"
        size="lg"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      {/* <AuthSection
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        type={router.query.type}
        providers={["google", "facebook", "twitter"]}
        afterAuthPath={router.query.next || "/dashboard"}
      /> */}
    </>
  );
}

export default requireAuth(OrderConfirmationPage);

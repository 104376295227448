import React from "react";
import {
  GlobeEuropeAfricaIcon,
  FingerPrintIcon,
  MapIcon,
  FolderArrowDownIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

function FeaturesSection(props) {
  const features = {
    // Left column
    left: [
      {
        title: "Unmatched Accuracy",
        description:
          "Powered by state-of-the-art on-device AI Models, delivering exceptional transcription accuracy in 99 languages.",
        icon: GlobeEuropeAfricaIcon,
        iconColor: "orange",
      },
      {
        title: "Privacy-First",
        description:
          "Transcription occurs entirely on your local device, ensuring your data is never uploaded to external servers.",
        icon: FingerPrintIcon,
        iconColor: "red",
      },
    ],
    // Right column
    right: [
      {
        title: "Easy Navigation",
        description:
          "Click on any transcript segment to navigate and playback audio instantly.",
        icon: MapIcon,
        iconColor: "emerald",
      },
      {
        title: "Versatile Export Options",
        description:
          "Export transcripts in various formats, including TXT, CSV, SRT, and VTT.",
        icon: FolderArrowDownIcon,
        iconColor: "purple",
      },
    ],
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      anchor={props.anchor}
    >
      <div className="container">
        <div className="flex flex-col space-y-12 lg:flex-row lg:items-center lg:space-y-0 lg:space-x-20">
          <div className="space-y-10 lg:w-5/12 xl:w-5/12 md:py-12">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              strapline={props.strapline}
            />
          </div>
          <div className="relative flex-none lg:w-7/12 xl:w-7/12">
            {/* <div className="absolute top-0 left-0 w-48 h-64 transform -translate-x-10 -translate-y-10 pattern-dots opacity-10 md:mt-20" /> */}
            {/* <div className="absolute bottom-0 right-0 w-48 h-64 transform translate-x-10 translate-y-10 pattern-dots opacity-10 md:mb-20" /> */}
            <div className="relative flex flex-col space-y-6 md:flex-row md:space-x-6 md:space-y-0">
              <div className="space-y-6 md:w-1/2 md:mt-24">
                {features.left.map((feature, index) => (
                  <div
                    className="p-5 transition duration-200 ease-out gray-border gray-background group rounded-2xl hover:border-gray-300"
                    key={index}
                  >
                    <FeatureIcon2 color={feature.iconColor} className="mb-6">
                      <feature.icon />
                    </FeatureIcon2>
                    <h4 className="mb-2 text-lg font-bold text-gray-300">
                      {feature.title}
                    </h4>
                    <p className="leading-relaxed text-gray-400">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>
              <div className="space-y-6 md:w-1/2">
                {features.right.map((feature, index) => (
                  <div
                    className="p-5 transition duration-200 ease-out gray-border gray-background group rounded-2xl hover:border-gray-300"
                    key={index}
                  >
                    <FeatureIcon2 color={feature.iconColor} className="mb-6">
                      <feature.icon />
                    </FeatureIcon2>
                    <h4 className="mb-2 text-lg font-bold text-gray-300">
                      {feature.title}
                    </h4>
                    <p className="leading-relaxed text-gray-400">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;

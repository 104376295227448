import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import PageLoader from "./../components/PageLoader";
import FormAlert from "./../components/FormAlert";
import { requireAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import { redirectToCheckout } from "./../util/stripe";

function PurchasePage(props) {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState();
  const { plan, mode } = router.query;
  // const mode = URLSearchParams(window.location.search).get("mode");

  useEffect(() => {
    // if (auth.user.planIsActive) {
    //   // If user already has an active plan
    //   // then take them to Stripe billing
    //   router.push("/settings/billing");
    // } else {
    // Otherwise go to checkout
    redirectToCheckout(plan, mode).catch((error) => {
      setFormAlert({
        type: "error",
        message: "err: " + error.message,
      });
    });
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Meta title="Purchase" />
      <PageLoader>
        {formAlert && (
          <div className="max-w-md mx-auto mb-4">
            <FormAlert type={formAlert.type} message={formAlert.message} />
          </div>
        )}
      </PageLoader>
    </>
  );
}

export default requireAuth(PurchasePage);

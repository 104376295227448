import React from "react";
import WaveryLogo from "./WaveryLogo";

function ListItem({ children }) {
  return <li className="list-outside list-item">{children}</li>;
}

function HeaderItem({ children }) {
  return (
    <h3 className="mb-3 text-xl font-semibold text-gray-200">{children}</h3>
  );
}

function ParagraphItem({ children }) {
  return <p className="text-gray-400 mb-7">{children}</p>;
}

function StrongItem({ children }) {
  return <strong className="text-gray-300">{children}</strong>;
}

function List({ children }) {
  return (
    <ul className="space-y-4 text-gray-400 list-disc list-inside mb-9">
      {children}
    </ul>
  );
}

function MigrationNotice() {
  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-primary sm:px-6 lg:px-8">
      <div className="w-full max-w-2xl p-8 rounded-lg shadow-lg gray-background gray-border">
        <h2 className="flex items-center gap-3 mb-4 text-2xl font-bold text-gray-200">
          <WaveryLogo className="w-8 h-8 " />
          Welcome to Your New Wavery Account!
        </h2>
        <ParagraphItem className="text-gray-400 mb-7">
          We're excited to announce some important changes that will improve
          your experience with WhisperScript!
        </ParagraphItem>
        <HeaderItem>What's New:</HeaderItem>
        <List>
          <ListItem>
            <StrongItem>Multi-Device Support:</StrongItem> Install WhisperScript
            on multiple machines, up to 2 devices simultaneously.
          </ListItem>
          <ListItem>
            <StrongItem>Simplified Account Management:</StrongItem> Licenses are
            now managed directly through Wavery for a smoother experience.
          </ListItem>
          <ListItem>
            <StrongItem>Super Fast and Responsive:</StrongItem> We've written
            WhisperScript from the ground up to be faster and more stable. In
            addition to a visual refresh, we've made the app more responsive and
            easier to use.
          </ListItem>
        </List>
        <HeaderItem>What Does This Mean for You?</HeaderItem>
        <List>
          <ListItem>
            <StrongItem>Create a New Wavery Account:</StrongItem> To continue
            using WhisperScript, please create your Wavery account. It only
            takes a moment, and we've made the process simple.
          </ListItem>
          <ListItem>
            <StrongItem>No More Lite Version:</StrongItem> While we're no longer
            offering a Lite version, you can now try the Pro version for free
            with our{" "}
            <span className="font-semibold text-cyan-300">free trial</span>. If
            you previously have a Gumroad Lite version license, we'll give you a
            full year of WhisperScript Pro for free.
          </ListItem>
          <ListItem>
            <StrongItem>New Pricing:</StrongItem> WhisperScript was written from
            the ground up, to be faster and more stable, and will allow us to
            ship more features faster. For existing Pro users, nothing changes -
            you already have access for life. We are grateful for your support!
            For all new users we are going to offer WhisperScript at a price,
            which we believe is fair for the value you get. However, we
            understand that it's always painful to pay a higher price, and we're
            here to support you through the transition. We're offering a year of
            WhisperScript Pro for free to all existing Lite users.
          </ListItem>
        </List>
        <ParagraphItem className="text-gray-400 mb-7">
          Thank you for being a valued user, and we look forward to continuing
          to improve WhisperScript with these new features and changes.
        </ParagraphItem>
        <ParagraphItem className="text-gray-400 mb-7">
          As always, If you have any questions or need assistance, just write us
          a mail at{" "}
          <a
            href="mailto:support@getwavery.com"
            className="text-blue-600 underline"
          >
            support@getwavery.com.
          </a>{" "}
          We're here to help!
        </ParagraphItem>
        {/* <div className="flex justify-center">
          <a
            href="/create-account"
            className="inline-block px-4 py-2 font-semibold text-white bg-blue-600 rounded-lg shadow hover:bg-blue-700"
          >
            Create Your Wavery Account
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default MigrationNotice;

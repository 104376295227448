import React from "react";
import Meta from "./../components/Meta";
// import ContactSection from "./../components/ContactSection";
import ContactSection2 from "./../components/ContactSection2";

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      {/* <ContactSection
        title="Get in touch"
        subtitle="If something does not make sense, feel free to contact us and we will get back to you as soon as possible."
        strapline=""
        size="md"
        bgColor="bg-blue-900"
        bgImage="https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?crop=entropy&fit=crop&h=800&q=80&w=1280"
        bgImageOpacity={0.17}
        textColor="text-white"
      /> */}
      <ContactSection2
        title="Get in touch"
        subtitle="Got a question, suggestion, or just want to say hi? We’re here to listen."
        strapline="Let's Chat!"
        size="lg"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
    </>
  );
}

export default ContactPage;

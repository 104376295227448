import React from "react";
import Meta from "./../components/Meta";
// import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth";
import SettingsSection from "./../components/SettingsSection";
import { useRouter } from "./../util/router";
// import AccountView from "./../components/AccountView/AccountView";

function DashboardPage(props) {
  const auth = requireAuth();
  const router = useRouter();

  // eslint-disable-next-line
  const user = auth.user;

  return (
    <>
      <Meta title="Account" />

      {/* <AccountView
        title="Account"
        subtitle="Manage your account info."
        strapline=""
        size="md"
        bgColor="bg-white"
      /> */}
      {/* <DashboardSection
        title="Account"
        subtitle="Manage your account info."
        strapline=""
        size="md"
        bgColor="bg-white"
      /> */}
      <SettingsSection
        size="lg"
        bgColor="bg-primary"
        bgImage=""
        textColor="text-gray-100"
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default requireAuth(DashboardPage);

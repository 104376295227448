import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";
// import { useContext } from "";
// import { useAuth } from "./../util/auth";

function AuthPage(props) {
  const router = useRouter();
  // const auth = useAuth();

  const queryParams = {
    gumroadLicenseKey: router.query.licenseKey || null,
    gumroadEmail: router.query.email || null,
    appName: router.query.appName || null,
    appVersion: router.query.appVersion || null,
    migrate: router.query.migrate || false,
    source: router.query.source || "web",
    next: router.query.next || "/dashboard",
    type: router.query.type || "signin",
  };

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        type={queryParams.type}
        providers={["apple", "github", "google"]}
        afterAuthPath={queryParams.next}
        queryParams={queryParams || {}}
      />
    </>
  );
}

export default AuthPage;

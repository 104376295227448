import React from "react";
import styles from "../styles/style";

function SectionHeader(props) {
  // Render nothing if no title or subtitle
  // if (!props.titleUpper && !props.subtitle && !props.strapline) {
  //   return null;
  // }
  return (
    <header
      className={
        "mb-8 last:mb-0 flex flex-col items-center justify-center" +
        (props.className ? ` ${props.className}` : "")
      }
    >
      {props.strapline && (
        // <div className="sm:text-[50px] font-bold text-white/50 tracking-tight sm:mb-3 mb-2">
        <div className="mb-2 text-lg font-bold tracking-tight sm:text-2xl md:text-3xl text-white/50 sm:mb-3">
          {props.strapline}
        </div>
      )}

      {props.title && (
        <h1
          className={`${styles.waveryTextLogo} text-center max-w-3xl text-[8vw] leading-[9vw] sm:text-5xl md:text-6xl sm:leading-tight md:leading-snug  whitespace-nowrap`}
        >
          {/* TODO: MAKE Mobile Responsive */}
          <div className="flex flex-col items-center justify-center ">
            {props.title}
            <br />
            {props.titleLower}
          </div>
        </h1> //text-3xl font-extrabold md:text-4xl
      )}

      {props.subtitle && (
        <h2 className="inline-block max-w-screen-md mt-4 text-base font-medium sm:text-lg md:text-xl md:leading-relaxed opacity-80">
          {props.subtitle}
        </h2>
      )}
    </header>
  );
}

export default SectionHeader;

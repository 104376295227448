import React, { useEffect, useState } from "react";
import Button from "./Button";
import Meta from "./Meta";
import Confetti from "react-confetti";
// import { useRouter } from "../util/router";
import Section from "./Section";
import { dragDropWorkflow } from "../assets";
import { layout } from "../styles/style";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import SectionHeader from "./SectionHeader";

function OrderConfirmationSection(props) {
  // const router = useRouter();
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    // Stop confetti after 10 seconds
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Meta title="Wavery - Order Confirmed" />
      {showConfetti && <Confetti />}
      <Section
        size={props.size}
        bgColor={props.bgColor}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        textColor={props.textColor}
        anchor={props.anchor}
      >
        <div className="container flex flex-col space-y-3 text-center lg:flex-row-reverse lg:space-y-0 lg:text-left">
          <div className="lg:w-1/2 lg:self-center">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              strapline={props.strapline}
            />
            <div className="flex flex-col items-center justify-center pt-10 pb-16 space-y-2 sm:flex-row sm:items-center lg:justify-start sm:space-y-0 sm:space-x-2">
              <a href="https://github.com/getwavery/whisperscript-2-releases/releases/download/v2.0.0/WhisperScript-2.0.0-arm64-mac.dmg">
                <Button
                  // component={Link}
                  // to="/pricing"
                  size="xl"
                  endIcon={
                    <ArrowRightIcon className="inline-block w-5 h-5 opacity-70" />
                  }
                >
                  Start Transcribing Now!
                </Button>
              </a>
              <a href={`${window.location.origin}/#features`}>
                <Button
                  // component={Link}
                  // to="/#features"
                  size="xl"
                  variant="light"
                  className="w-fit"
                >
                  Learn more
                </Button>
              </a>
            </div>
          </div>
          <div className="pb-12 lg:w-1/2 lg:mr-16 lg:flex lg:justify-center lg:items-center md:pb-0">
            <div className="relative">
              {/* VIDEO */}
              <div
                style={{ aspectRatio: "auto 82/59" }}
                className={`${layout.sectionImgReverse} max-h-[400px] w-full md:mt-0 mt-10`}
              >
                <div className="rcorners aspect-[82/59] max-h-[400px]">
                  <video
                    src={dragDropWorkflow}
                    alt="whisperscript-transcribing"
                    type="video/mp4"
                    className="w-full h-auto"
                    autoPlay
                    muted
                    loop
                    playsInline
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

export default OrderConfirmationSection;

import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function StatsSection(props) {
  const stats = [
    {
      title: "languages",
      count: "99+",
    },
    {
      title: "faster than realtime transcription",
      count: "50x",
    },
    {
      title: "Offline",
      count: "100%",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-16">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 text-center divide-y sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
          {stats.map((stat, index) => (
            <dl className="p-5 space-y-1" key={index}>
              <dt className="text-4xl font-extrabold font-poppins font-semibold text-gradient sm:text-[58px] text-[50px] sm:leading-[72px] leading-[56px]">{stat.count}</dt>
              <dd className="text-sm font-semibold tracking-wide text-gray-600 uppercase">
                {stat.title}
              </dd>
            </dl>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default StatsSection;

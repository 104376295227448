import React from "react";
import Meta from "./../components/Meta";
import FaqSection from "./../components/FaqSection";
import CtaSection from "./../components/CtaSection";

function FaqPage(props) {
  return (
    <>
      <Meta title="Faq" />
      <FaqSection
        title="Frequently Asked Questions"
        subtitle=""
        strapline="FAQ"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        showSupportButton={true}
        supportUrl={`${window.location.origin}/contact`}
      />
      {/* <FaqSection2
        title="Frequently Asked Questions"
        subtitle=""
        strapline="We answer"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        showSupportButton={true}
        supportUrl="https://getwavery.com/contact"
      /> */}
      <CtaSection
        title={
          <>
            Ready to Try <span className="text-waveryBlue">WhisperScript</span>?
          </>
        }
        subtitle="You've heard enough about the details, but nothing beats experiencing speed and accuracy of WhisperScript for yourself. It's free to try, what are you waiting for?"
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        buttonLink="https://github.com/getwavery/whisperscript-2-releases/releases/download/v2.0.0/WhisperScript-2.0.0-arm64-mac.dmg"
        buttonText="Get Started Today!"
      />
    </>
  );
}

export default FaqPage;

// import React, { useState } from "react";
import FormAlert from "./FormAlert";
// import EditItemModal from "./EditItemModal";
import { useAuth } from "./../util/auth";
// import { useItemsByOwner } from "./../util/db";
import { SparklesIcon } from "@heroicons/react/24/solid";

function DashboardItems(props) {
  const auth = useAuth();

  // const {
  //   data: products,
  //   status: itemsStatus,
  //   error: itemsError,
  // } = useItemsByOwner(auth.user.uid);

  // const [creatingItem, setCreatingItem] = useState(false);

  // const [updatingItemId, setUpdatingItemId] = useState(null);

  const products = auth.user.products;

  const itemsAreEmpty = !products || products.length === 0;

  return (
    <>
      {itemsAreEmpty && (
        <div className="mb-4">
          <FormAlert
            type="error"
            message={"There was an error fetching your Wavery Products"}
          />
        </div>
      )}

      <div>
        <div className="flex items-center justify-between p-4">
          <span className="text-xl">
            <strong>Your Wavery Products</strong>
          </span>
        </div>

        {/* {(itemsStatus === "loading" || itemsAreEmpty) && (
          <div className="p-8">
            {itemsStatus === "loading" && <>Loading...</>}

            {itemsStatus !== "loading" && itemsAreEmpty && (
              <>
                You don't have any Wavery products. Contact us if you think
                something isn't right.
              </>
            )}
          </div>
        )} 
         
         itemsStatus !== "loading" && */}

        {products && products.length > 0 && (
          <div className="overflow-x-auto">
            <table className="min-w-full text-gray-300 rounded-lg shadow-md bg-primary">
              <thead className="text-white bg-gray-800">
                <tr>
                  <th className="w-6 py-2"></th>
                  <th className="w-1/5 px-4 py-2">Product Name</th>
                  <th className="w-1/5 px-4 py-2">Version</th>
                  <th className="w-1/5 px-4 py-2">Active</th>
                  <th className="w-1/5 px-4 py-2">Purchase Date</th>
                  <th className="w-auto px-4 py-2">License Key</th>
                </tr>
              </thead>
              <tbody className="text-gray-300">
                {products.map((product, index) => (
                  <tr key={index} className="">
                    <td className="px-4 py-2">
                      <SparklesIcon className="w-6" />
                    </td>
                    <td className="px-4 py-2">{product.name}</td>
                    <td className="px-4 py-2">{product.appVersion}</td>
                    <td className="px-4 py-2">
                      {product.hasAccess ? "Active" : "Inactive"}
                    </td>
                    <td className="px-4 py-2">
                      {new Date(product.createdAt).toLocaleDateString()}
                    </td>
                    <td className="w-auto px-4 py-2">{product.id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* {creatingItem && <EditItemModal onDone={() => setCreatingItem(false)} />} */}

      {/* {updatingItemId && (
        <EditItemModal
          id={updatingItemId}
          onDone={() => setUpdatingItemId(null)}
        />
      )} */}
    </>
  );
}

export default DashboardItems;

import React from "react";
import {
  AdjustmentsHorizontalIcon,
  ArrowDownOnSquareStackIcon,
  LockClosedIcon,
  DocumentTextIcon,
  BoltIcon,
  ArrowUpOnSquareIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

function FeaturesSection2(props) {
  const features = [
    {
      title: "Customizable",
      description:
        "WhisperScript adapts to your needs with adjustable model parameters and settings that give you full control over the transcription process, ensuring precision and flexibility for every project.",
      icon: AdjustmentsHorizontalIcon,
      iconColor: "orange",
    },
    {
      title: "Batch Processing",
      description:
        "Transcribe multiple audio or video files simultaneously with WhisperScript's batch processing feature, designed to save time and enhance your workflow efficiency.",
      icon: ArrowDownOnSquareStackIcon,
      iconColor: "red",
    },

    {
      title: "Supports Multiple Formats",
      description:
        "WhisperScript handles various audio formats (MP3, WAV, M4A) and video formats (MP4, MOV, MKV) to ensure compatibility with your media content, no matter the source.",
      icon: DocumentTextIcon,
      iconColor: "emerald",
    },
    {
      title: "Fast & Responsive UI",
      description:
        "WhisperScript's sleek, intuitive interface is optimized for speed, providing quick and smooth navigation for seamless transcription, editing, and playback.",
      icon: BoltIcon,
      iconColor: "purple",
    },
    {
      title: "On-Device Processing",
      description:
        "Your data stays secure with WhisperScript. All transcription occurs directly on your device, keeping your audio files private and never uploading them to external servers.",
      icon: LockClosedIcon,
      iconColor: "blue",
    },
    {
      title: "Export in Various Formats",
      description:
        "Easily export your transcripts in a range of formats (TXT, CSV, SRT, VTT) to suit your project requirements, from subtitle generation to detailed text-based reports.",
      icon: ArrowUpOnSquareIcon,
      iconColor: "pink",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      anchor={props.anchor}
    >
      <div className="container space-y-16">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-5">
          {features.map((feature, index) => (
            <div
              className="p-5 text-center transition duration-200 ease-out group rounded-2xl"
              key={index}
            >
              <FeatureIcon2
                color={feature.iconColor}
                size="large"
                className="mb-12"
              >
                <feature.icon />
              </FeatureIcon2>
              <h4 className="mb-2 text-lg font-bold">
                <div className="text-cyan-300">{feature.title}</div>
              </h4>
              <p className="leading-relaxed text-gray-100">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection2;

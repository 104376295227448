import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { kaiProfile, jonProfile } from "../assets";

function TeamBiosSection2(props) {
  const teamBios = [
    {
      avatar: jonProfile,
      name: "Jonathan Ho",
      role: "CEO",
    },
    {
      avatar: kaiProfile,
      name: "Kai Shimada",
      role: "CTO",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-16">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid justify-center grid-cols-1 gap-8 mx-auto text-center sm:grid-cols-2 md:gap-16">
          {teamBios.map((bio, index) => (
            <div key={index} className="flex flex-col items-center">
              <span className="inline-block p-2 mb-5 bg-white rounded-full shadow-lg">
                <img
                  className="inline-block w-40 h-40 rounded-full"
                  src={bio.avatar}
                  alt="User avatar"
                />
              </span>
              <h4 className="mb-1 text-xl font-semibold">{bio.name}</h4>
              <p className="font-medium text-gray-600">{bio.role}</p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default TeamBiosSection2;

import React, { useState, useEffect } from "react";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
// import './Notification.css'; // Import the CSS file for styling

const Notification = () => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // 'error' or 'success'
  const [open, setOpen] = useState(true);

  useEffect(() => {
    // Get the hash parameters from the URL
    const hash = window.location.hash.substring(1); // Remove the '#' character
    const params = new URLSearchParams(hash);

    // Decode the parameters to handle URL-encoded characters
    const error = params.get("error");
    const errorDescription = params.get("error_description");
    const messageParam = params.get("message");

    if (error) {
      setMessage(decodeURIComponent(errorDescription || "An error occurred."));
      setMessageType("error");
    } else if (messageParam) {
      setMessage(decodeURIComponent(messageParam));
      setMessageType("success");
    }
  }, []);

  // If there's no message, don't render anything
  if (!message) {
    return null;
  }

  return (
    <>
      {open && (
        <div className={`notification-container ${messageType}`}>
          <div className="notification-message" onClick={() => setOpen(false)}>
            {messageType === "error" ? (
              <ExclamationTriangleIcon className="w-[18px] h-[18px] mr-1 shrink-0" />
            ) : (
              <InformationCircleIcon className="w-[18px] h-[18px] mr-2 shrink-0" />
            )}
            <span>{message}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;

// LaunchElectronPage.js
import React, { useEffect, useRef } from "react";
import Meta from "./../components/Meta";
import CtaSection from "../components/CtaSection";
import { requireAuth, useAuth } from "./../util/auth.js";
import supabase from "./../util/supabase";
import { apiRequest } from "../util/util.js";
// import { useItemsByOwner } from "../util/db.js";
import { useRouter } from "../util/router.js";
// import useItemsByOwner from "./../util/db.js";

function LaunchElectronPage(props) {
  // eslint-disable-next-line
  const auth = requireAuth();

  const session = useAuth();
  const router = useRouter();
  // eslint-disable-next-line
  const userSession = session.user;
  // const session = auth.session;

  const openElectronUri = useRef();

  console.log("You are on the latest session - launchElectronPage");

  useEffect(() => {
    console.log("You are on the latest session - blah");
    // Function to generate a magic link and redirect to the Electron app
    async function handleGenerateLink() {
      try {
        // Retrieve the current session from Supabase
        const {
          data: { session },
        } = await supabase.auth.getSession();

        // Use the session object to check if the user is authorized to generate
        // a link for the specific version of the Electron App. This is just a
        // preliminary check, but can't be relied on for authorization, since
        // the URL parameters can be easily modified

        // const appName = props.URLSearchParams.get("appName") || "WhisperScript";
        // const appVersion = props.URLSearchParams.get("appVersion");

        console.log("userSession: ", userSession);
        //TODO: add semantic version check here @jonathgh
        const authorizedProduct = userSession?.products?.find(
          (product) =>
            product.appName === "WhisperScript" && product.hasAccess === true
        );

        // console.log("authorizedProduct", authorizedProduct);

        if (!authorizedProduct) {
          // redirect user to pricing page
          router.push("/pricing");
          return;
        } else {
          // Extract the email from the session user object
          const email = session.user.email;

          // Make an API request to generate the magic link for the user
          const data = await apiRequest("generateLink", "POST", { email });

          // Check if the hashed_token exists in the response data
          if (data) {
            // Extract the hashed token from the response

            const params = new URLSearchParams({
              email: email,
              hashed_token: data,
            });

            // Construct the custom protocol URL with the hashed token
            const uri = new URL("whisperscript://open?" + params);
            openElectronUri.current = uri.href;

            // Redirect the browser to the custom protocol URL to launch the Electron app
            window.location.href = uri;
          } else {
            // Log the response data for debugging
            console.error("Unexpected data format:", data);
            // alert("Failed to generate OTP link. Please try again.");
          }
        }
      } catch (error) {
        // Handle errors thrown by apiRequest, which will be handled by CustomError
        console.error("Error generating OTP link:", error);
        // alert(
        //   "An error occurred while generating the OTP link. Please try again."
        // );
      }
    }

    handleGenerateLink();
  }, [router, userSession]);

  return (
    <>
      <Meta title="Launch Desktop App" />
      {userSession && (
        <CtaSection
          title="You're all set"
          subtitle="Click allow to launch the desktop app, or click on the button below."
          strapline="Success!"
          size="lg"
          bgColor="bg-primary"
          bgImage=""
          bgImageOpacity={1}
          textColor="text-gray-100"
          customButton={true}
        />
      )}
    </>
  );
}

export default requireAuth(LaunchElectronPage);

import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { existBMWK, existEU, existLogo } from "../assets";

function EXISTSection(props) {
  const icons = [
    {
      src: existBMWK,
      alt: "logo-bmwk",
      className:
        "w-32 h-32 object-contain transform transition-transform duration-300 hover:scale-105",
    },
    {
      src: existEU,
      alt: "logo-EU",
      className:
        "w-32 h-32 object-contain transform transition-transform duration-300 hover:scale-105",
    },
    {
      src: existLogo,
      alt: "logo-EXIST",
      className:
        "w-32 h-32 object-contain transform transition-transform duration-300 hover:scale-105",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-16">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 text-center divide-y sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
          {icons.map((icon, index) => (
            <div className="p-5 space-y-1" key={index}>
              <div className="flex flex-col items-center bg-gray-100 gray-border group rounded-2xl hover:border-gray-300">
                <img src={icon.src} alt={icon.alt} className={icon.className} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default EXISTSection;

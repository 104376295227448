import React from "react";
import Meta from "../components/Meta";
import HeroSection3 from "../components/HeroSection3";

function TrialPage(props) {
  return (
    <>
      <Meta title="Wavery - OrderConfirmed" />
      <HeroSection3
        title="Start Your Free Trial Now"
        subtitle="Get started right away with a 7 day free trial"
        strapline="Welcome to Wavery"
        size="lg"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      {/* <AuthSection
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        type={router.query.type}
        providers={["google", "facebook", "twitter"]}
        afterAuthPath={router.query.next || "/dashboard"}
      /> */}
    </>
  );
}

export default TrialPage;

import React from "react";
import PropTypes from "prop-types";

export default class Icon extends React.Component {
  render() {
    return (
      <div>
        <img
          src={this.props.src}
          alt={this.props.alt}
          width={this.props.width}
          height={this.props.height}
        />
      </div>
    );
  }
}

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

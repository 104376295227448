const styles = {
  boxWidth: "sm:max-w-[1280px] w-full",

  heading2:
    "font-poppins font-semibold sm:text-[42px] text-[28px] text-white sm:leading-[60px] leading-[1.3em] w-full",
  paragraph:
    "font-sans font-medium text-white/60 sm:text-[24px] text-[17px] sm:leading-[32px] leading-6 sm:mt-5 mt-4",

  subheading:
    "font-sans font-normal text-white/60 sm:text-[30px] text-[3.8vw] sm:leading-[35px] leading-[4.2vw]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",
  flexGrow: "flex-grow",
  flexCol: "flex flex-col",

  paddingX: "sm:px-16 px-[15px]",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-12 py-4",

  marginX: "sm:mx-16 mx-6",
  marginY: "sm:my-16 my-6",

  // waveryTextLogo: "font-sans font-bold transition-colors text-gray-100",
  waveryTextLogo:
    "font-sans font-bold transition-colors text-transparent bg-clip-text bg-gradient-to-r from-gray-300 from-20% via-gray-100 via 60% to-gray-300 to-80%",

  fullHeight: "min-h-screen flex flex-col",
  flexGrowFull: "flex-grow",
  footerBottom: "mt-auto",
};

export const layout = {
  section: `flex md:flex-row flex-col ${styles.paddingY} sm:px-0 px-[15px]`,
  sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

  sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 relative`,
  sectionImg: `flex-1 flex justify-center items-start md:gap-4 md:ml-10 ml-0 md:mt-0 mt-10 relative`,

  sectionInfo: `flex-1 flex justify-start items-start flex-col`,
};

export default styles;

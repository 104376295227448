import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "./../util/auth";
import { discordIcon } from "../assets";
import { waveryIcon } from "../assets";
import { ComputerDesktopIcon } from "@heroicons/react/24/solid";

function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  function hasProductAccess(products, appName) {
    return products?.some(
      (product) => product.appName === appName && product.hasAccess === true
    );
  }

  const hasWhisperScriptAccess = hasProductAccess(
    auth.user?.products,
    "WhisperScript"
  );

  return (
    <>
      <form className="container space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          type="text"
          id="name"
          placeholder="Name"
          label="Name"
          textColor="text-gray-900"
          defaultValue={auth.user.waveryUsername}
          error={errors.name}
          {...register("waveryUsername")}
        />
        <TextField
          type="email"
          id="email"
          placeholder="Email"
          label="Email"
          defaultValue={auth.user.email}
          error={errors.email}
          {...register("email", { required: "Please enter an email" })}
        />
        <Button
          type="submit"
          size="md"
          variant="primary"
          disabled={pending}
          isBlock={true}
        >
          {!pending && <>Save</>}

          {pending && <LoadingIcon className="w-6" />}
        </Button>
      </form>

      <div className="container max-w-md py-20 mx-auto mt-5">
        <div className="flex flex-col justify-center gap-3">
          {hasWhisperScriptAccess && (
            <>
              <Button
                size="lg"
                href="https://github.com/getwavery/whisperscript-2-releases/releases/download/v2.0.0/WhisperScript-2.0.0-arm64-mac.dmg"
                variant="simple"
                startIcon={<img src={waveryIcon} alt="Discord Icon" />}
              >
                Download WhisperScript 2 (Apple Silicon)
              </Button>
              <Button
                size="lg"
                href={`${window.location.origin}/launchapp`}
                variant="simple"
                startIcon={<ComputerDesktopIcon className="w-6 h-6" />}
              >
                Launch WhisperScript on Desktop
              </Button>
            </>
          )}
          <Button
            size="lg"
            href="https://discord.gg/D45a4yQRp8"
            variant="simple"
            startIcon={<img src={discordIcon} alt="Discord Icon" />}
            style={{ backgroundColor: "#5865F2", color: "#fff" }}
          >
            Join our Discord Server
          </Button>
        </div>
      </div>
    </>
  );
}

export default SettingsGeneral;

// function ProfileTab() {
//   const { user } = useAuth();
//   const [usernameFormOpen, setUsernameFormOpen] = useState(false);
//   const [emailFormOpen, setEmailFormOpen] = useState(false);

//   const username = user?.user_metadata?.wavery_username;
//   const email = user?.user_metadata?.email;

//   return (
//     <>
//       {/* Tab Title */}
//       <header className="px-4 pb-5 border-b border-gray-800 sm:px-0">
//         <h1 className="text-[18px] font-semibold leading-7 text-gray-100">
//           Profile
//         </h1>
//         {/* <h2 className="max-w-2xl mt-1 text-sm font-normal leading-6 text-gray-400">
//           Personal details and application.
//         </h2> */}
//       </header>

//       {/* Tab Content */}
//       <DataList>
//         {/* Username Form */}
//         <fieldset
//           tabIndex={0}
//           onKeyDown={(e) => closeFormOnEscPress(e, setUsernameFormOpen)}
//           className="outline-none"
//         >
//           <DataList.Item align="center">
//             <DataList.Label>Username</DataList.Label>
//             <DataList.Value subgrid>
//               {username ? (
//                 <>
//                   <div className="inline-flex items-center gap-3.5 overflow-hidden">
//                     <div
//                       aria-label="User Avatar"
//                       className="inline-flex size-[50px] shrink-0 select-none items-center justify-center overflow-hidden rounded-full border border-stronger-alt bg-control p-2 align-middle"
//                     >
//                       <p className="text-[20px] font-medium uppercase text-gray-200">
//                         {username?.slice(0, 1) || "J"}
//                       </p>
//                     </div>
//                     <p className="text-sm font-medium text-gray-200">
//                       {username || "John Doe"}
//                     </p>
//                   </div>
//                   <div className="inline-flex items-center justify-end">
//                     <OpenFormButton onPress={() => setUsernameFormOpen(true)}>
//                       Update username
//                     </OpenFormButton>
//                   </div>
//                 </>
//               ) : (
//                 <div className="inline-flex items-center justify-start">
//                   <OpenFormButton onPress={() => setUsernameFormOpen(true)}>
//                     <Plus className="stroke-2 size-4" />
//                     Add username
//                   </OpenFormButton>
//                 </div>
//               )}
//             </DataList.Value>
//           </DataList.Item>

//           <UsernameForm
//             username={username}
//             formOpen={usernameFormOpen}
//             setFormOpen={setUsernameFormOpen}
//           />
//         </fieldset>

//         {/* Email Form */}
//         <fieldset
//           tabIndex={0}
//           onKeyDown={(e) => closeFormOnEscPress(e, setEmailFormOpen)}
//           className="outline-none"
//         >
//           <DataList.Item>
//             <DataList.Label>Email address</DataList.Label>
//             <DataList.Value subgrid>
//               <div className="ml-2 inline-flex items-center gap-2.5">
//                 <p className="text-[13px]">{email}</p>
//               </div>
//               <div className="inline-flex items-center justify-end">
//                 <OpenFormButton onPress={() => setEmailFormOpen(true)}>
//                   Update email
//                 </OpenFormButton>
//               </div>
//             </DataList.Value>
//           </DataList.Item>

//           <EmailForm
//             email={email}
//             formOpen={emailFormOpen}
//             setFormOpen={setEmailFormOpen}
//           />
//         </fieldset>

//         {/* Connected Accounts */}
//         {/* <DataList.Item>
//           <DataList.Label>Connected accounts</DataList.Label>
//           <DataList.Value>
//             <div className="flex flex-col items-start gap-1">
//               <div className="inline-flex items-center justify-end">
//                 <Button className="flex h-fit cursor-default select-none items-center gap-1 text-sm font-medium leading-6 text-sky-400/90 outline-none transition duration-100 pressed:brightness-[.8]">
//                   <Plus className="stroke-2 size-4" />
//                   Connect account
//                 </Button>
//               </div>
//             </div>
//           </DataList.Value>
//         </DataList.Item> */}
//       </DataList>
//     </>
//   );
// }

// function SecurityTab() {
//   const [passwordFormOpen, setPasswordFormOpen] = useState(false);

//   return (
//     <>
//       {/* Tab Title */}
//       <header className="px-4 pb-5 border-b border-gray-800 sm:px-0">
//         <h1 className="text-[18px] font-semibold leading-7 text-gray-100">
//           Security
//         </h1>
//         {/* <h2 className="max-w-2xl mt-1 text-sm leading-6 text-gray-400">Manage password.</h2> */}
//       </header>

//       {/* Tab Content */}
//       <DataList>
//         <fieldset
//           tabIndex={0}
//           onKeyDown={(e) => closeFormOnEscPress(e, setPasswordFormOpen)}
//           className="outline-none"
//         >
//           <DataList.Item>
//             <DataList.Label>Password</DataList.Label>
//             <DataList.Value subgrid>
//               <div className="ml-2 font-[1000] tracking-widest text-gray-200">
//                 {"••••••••••••"}
//               </div>
//               <div className="inline-flex items-center justify-end">
//                 <OpenFormButton onPress={() => setPasswordFormOpen(true)}>
//                   Update password
//                 </OpenFormButton>
//               </div>
//             </DataList.Value>
//           </DataList.Item>

//           <PasswordForm
//             formOpen={passwordFormOpen}
//             setFormOpen={setPasswordFormOpen}
//           />
//         </fieldset>

//         {/* Active Devices */}
//         {/* <DataList.Item>
//           <DataList.Label>Active devices</DataList.Label>
//           <DataList.Value>
//             <ul className="flex flex-col items-start justify-center gap-4">
//               {Array.from([...new Array(2)]).map((item, i) => (
//                 <li
//                   key={i}
//                   id={`device-${i + 1}`}
//                   className="group inline-flex h-[60px] w-full items-start justify-between gap-4 rounded-md font-medium text-gray-300"
//                 >
//                   <div className="flex items-center px-2">
//                     <Monitor className="size-6 fill-black/20 stroke-[1.3] text-gray-500" />
//                     <p className="ml-4 font-normal">{`Macintosh ${i + 1}`}</p>
//                     <span className="ml-3 hidden h-[21px] items-center justify-center rounded-md border border-gray-300/10 bg-gray-300/5 px-1.5 text-[11px] text-gray-300/90 group-first:inline-flex">
//                       This device
//                     </span>
//                   </div>
//                   <ActionDropdown>
//                     <ActionDropdown.Button className="h-fit cursor-default select-none rounded-md text-sm font-medium text-gray-500 outline-none transition ease-out active:text-gray-400 data-[state=open]:text-gray-200 hover:text-gray-200">
//                       <Ellipsis className="size-[22px]" />
//                     </ActionDropdown.Button>
//                     <ActionDropdown.Menu align="end">
//                       <ActionDropdown.MenuItem onSelect={() => handleLogOutDevice} label="Log out from this device" />
//                     </ActionDropdown.Menu>
//                   </ActionDropdown>
//                 </li>
//               ))}
//             </ul>
//           </DataList.Value>
//         </DataList.Item> */}

//         {/* Delete Account */}
//         {/* <DataList.Item>
//           <DataList.Label>Delete account</DataList.Label>
//           <DataList.Value>
//             <Button
//               onPress={handleDeleteAccount}
//               className="flex h-[28px] w-fit cursor-default select-none items-center justify-center rounded-md px-4 font-medium text-red-500/90 outline-none transition duration-100 hover:bg-red-500/5 hover:text-red-500 pressed:brightness-[.8]"
//             >
//               Delete account
//             </Button>
//           </DataList.Value>
//         </DataList.Item> */}
//       </DataList>
//     </>
//   );
// }
// // Helper Functions
// const closeFormOnEscPress = (e, setFormOpen) => {
//   if (e.key === "Escape") {
//     e.target.form?.reset();
//     setFormOpen(false);
//   }
// };

// // Helper Components
// function OpenFormButton({ onPress, children }) {
//   // const isOnline = useOnlineStatus();
//   return (
//     <Button
//       onPress={onPress}
//       className="inline-flex h-fit cursor-default select-none items-center gap-1 text-sm font-medium leading-6 text-sky-400/90 outline-none transition duration-200 ease-out pressed:brightness-[.8] disabled:text-gray-400 disabled:opacity-40"
//       // isDisabled={!isOnline}
//     >
//       {children}
//     </Button>
//   );
// }

// function PasswordForm({ formOpen, setFormOpen }) {
//   const [formState, setFormState] = useState("idle");

//   const {
//     register,
//     handleSubmit,
//     reset,
//     setError,
//     getValues,
//     clearErrors,
//     formState: { errors },
//   } = useForm({ mode: "onChange" });

//   const onSubmit = async ({ password }) => {
//     setFormState("loading");

//     const updateData = { password };

//     try {
//       // Send updateUser request
//       const { user, error } = await window.electron.ipcRenderer.invoke(
//         "supabase.updateUser",
//         updateData
//       );

//       if (error) throw new Error(error.message);

//       if (user) {
//         setTimeout(() => {
//           setFormState("success");
//           // showNotificationToast("success", "Password Updated");
//         }, 550);
//         setTimeout(() => {
//           setFormState("idle");
//           handleReset();
//         }, 1350);
//       }
//     } catch (error) {
//       if (error instanceof Error) {
//         // await showMessageBox({ type: "error", message: error.message });
//         setFormState("idle");
//       }
//     }
//   };

//   const handleReset = (e) => {
//     e?.preventDefault();
//     setFormOpen(false);
//     reset();
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
//       <AnimatePresence>
//         {formOpen && (
//           <motion.fieldset
//             initial="enter"
//             animate="open"
//             exit="exit"
//             variants={variantsListItem}
//             disabled={formState === "loading" || formState === "success"}
//             className="outline-none group"
//           >
//             <DataList.Item className="pt-0.5 sm:gap-y-2">
//               <DataList.Label>New password</DataList.Label>
//               <DataList.Value>
//                 <div className="flex flex-col transition duration-200 group-disabled:pointer-events-none group-disabled:opacity-50">
//                   <input
//                     {...register("password", {
//                       required: "Password is required",
//                       onChange: ({ target: { value } }) => {
//                         const { cpassword } = getValues();
//                         if (cpassword) {
//                           cpassword === value
//                             ? clearErrors("cpassword")
//                             : setError("cpassword", {
//                                 message: "Passwords do not match",
//                               });
//                         }
//                       },
//                       minLength: {
//                         value: 6,
//                         message: "Password must be at least 6 characters",
//                       },
//                     })}
//                     type="password"
//                     placeholder="Password"
//                     autoFocus
//                     className="mb-1.5 w-full max-w-[240px] rounded-md border border-gray-200/[.125] bg-darkgray-800/80 px-2 py-0.5 text-[13px] text-gray-200 outline-none transition ease-out placeholder:text-gray-200/40 focus:border-sky-400/75 focus:shadow-md focus:ring-1 focus:ring-sky-400/75"
//                   />
//                   <FormInputError error={errors.password} />
//                 </div>
//               </DataList.Value>

//               <DataList.Label>Confirm password</DataList.Label>
//               <DataList.Value>
//                 <div className="flex flex-col transition duration-200 group-disabled:pointer-events-none group-disabled:opacity-50">
//                   <input
//                     {...register("cpassword", {
//                       required: "Confirm password is required",
//                       validate: (value) => {
//                         const { password } = getValues();
//                         return password === value || "Passwords do not match";
//                       },
//                     })}
//                     type="password"
//                     placeholder="Confirm Password"
//                     className="mb-1.5 w-full max-w-[240px] rounded-md border border-gray-200/[.125] bg-darkgray-800/80 px-2 py-0.5 text-[13px] text-gray-200 outline-none transition ease-out placeholder:text-gray-200/40 focus:border-sky-400/75 focus:shadow-md focus:ring-1 focus:ring-sky-400/75"
//                   />
//                   <FormInputError error={errors.cpassword} />
//                 </div>
//               </DataList.Value>

//               <DataList.Label></DataList.Label>
//               <DataList.Value>
//                 <div className="flex w-full max-w-[240px] justify-between">
//                   <FormSubmitResetButtons formState={formState} />
//                 </div>
//               </DataList.Value>
//             </DataList.Item>
//           </motion.fieldset>
//         )}
//       </AnimatePresence>
//     </form>
//   );
// }

// // Form Subcomponents
// function FormInputError({ error }) {
//   return (
//     <AnimatePresence initial={false}>
//       {error && (
//         <motion.div
//           layout
//           initial="enter"
//           animate="open"
//           exit="exit"
//           variants={variantsInputError}
//           className="flex items-center"
//         >
//           <p className="text-start text-xs font-medium text-[#E5484D]">
//             {error.message}
//           </p>
//         </motion.div>
//       )}
//     </AnimatePresence>
//   );
// }

// function FormSubmitResetButtons({
//   formState,
//   defaultSubmitLabel = "Update",
// }: {
//   formState: FormState,
//   defaultSubmitLabel?: string,
// }) {
//   return (
//     <>
//       <Button
//         type="reset"
//         className="flex cursor-default select-none items-center justify-center px-2 py-px text-[13px] font-medium text-gray-300/80 outline-none transition-all duration-100 hover:text-gray-300 pressed:brightness-[.8]"
//       >
//         Cancel
//       </Button>
//       <StateButton
//         type="submit"
//         state={formState}
//         stateValues={{ idle: defaultSubmitLabel }}
//         className="inline-flex h-[28px] w-[88px] items-center justify-center rounded-md border border-zinc-400/15 bg-gradient-to-br from-arcgray-500/90 to-zinc-600/60 to-80% text-[13px] font-medium text-gray-200 shadow-[0_0px_50px_-13px] shadow-white/50 transition-all duration-100 hover:active:brightness-[.8]"
//       />
//     </>
//   );
// }

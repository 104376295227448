import React from "react";
import { BookmarkIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { plans } from "../constants";
import { waveryIconLarge } from "../assets";

function PricingSection(props) {
  const auth = useAuth();

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-10">
        <div className="flex justify-center">
          <div className=" sm:w-[150px] w-[125px] sm:h-[150px] h-[125px] sm:mb-5 mb-5">
            <img
              src={waveryIconLarge}
              alt="WhisperScript-Icon"
              className="aspect-square app-icon w-full h-auto rounded-[34px]"
            />
          </div>
        </div>

        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:gap-0 lg:py-6">
          {plans.map((plan, index) => (
            <div
              className={
                "gray-border gray-background rounded-lg shadow-sm flex flex-col border-2" +
                (plan.featured
                  ? " bg-blue-50 lg:border-2 border-sky-300 hover:border-sky-400 relative lg:-mx-2 lg:-my-6"
                  : "") +
                (!plan.featured
                  ? " bg-blue-50 border-gray-200 hover:border-sky-400"
                  : "")
              }
              key={index}
            >
              {plan.featured && (
                <div className="absolute top-0 right-0 flex items-center justify-center w-10 h-10">
                  <BookmarkIcon className="inline-block w-6 h-6 text-orange-400" />
                </div>
              )}

              <div className="p-5 text-center bg-gray-900 rounded-t-lg lg:p-6">
                <span className="inline-block px-3 py-1 mb-4 text-sm font-semibold tracking-wider text-gray-200 uppercase bg-opacity-50 rounded-full bg-sky-500">
                  {plan.name}
                </span>
                <div className="mb-1">
                  <span className="text-3xl font-extrabold text-gray-100 lg:text-5xl">
                    €{plan.price}
                  </span>
                  <span className="font-semibold text-gray-200">
                    {plan.billingCycle}
                  </span>
                  <p className="text-sm font-medium text-gray-600">
                    {plan.subtitle}
                  </p>
                </div>
              </div>

              {plan.perks && (
                <div
                  className={
                    "p-5 lg:p-6 space-y-5 lg:space-y-6 text-gray-300 grow" +
                    (plan.featured ? " text-sky-200" : "")
                  }
                >
                  <ul className="space-y-4 text-sm lg:text-base">
                    {plan.perks.map((perk, index) => (
                      <li className="flex items-center space-x-2" key={index}>
                        <CheckCircleIcon className="inline-block w-5 h-5 text-emerald-500" />
                        <span>{perk}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="px-5 pb-5 lg:px-6 lg:pb-6">
                <Button
                  component={Link}
                  to={
                    auth.user
                      ? `/purchase/${plan.id}?mode=${plan.mode}`
                      : `/auth/signup?next=/purchase/${plan.id}?mode=${plan.mode}`
                  }
                  size="lg"
                  variant={plan.featured ? "primary" : "dark"}
                  isBlock={true}
                >
                  {plan.mode === "subscription"
                    ? "Start Free Trial"
                    : plan.mode === "payment"
                    ? "Get Lifetime Access"
                    : "Start Free Trial"}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default PricingSection;
